import {inject, Injector} from "@angular/core";
import {environment} from "../../environments/environment";

export class DIControlService {
  static created = {};

  constructor(){
    if (!environment.production) {
      const serviceName = this.constructor.name
      DIControlService.created[serviceName] = (DIControlService.created?.[serviceName] + 1) || 1;

      if (DIControlService.created[serviceName] > 1) {
        const injector = inject(Injector)
        console.log(`%c${serviceName} создан %c${DIControlService.created?.[serviceName]}%c раз`, 'color: green', 'color: orangered', 'color: green');
        console.log(`%cscope: %c${injector['scope']}`, 'color: green', 'color: orangered')
        console.log(`%csource: %c${injector['source']}`, 'color: green', 'color: orangered')
      }
    }
  }
}
