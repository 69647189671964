import {APP_BASE_HREF, CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {AppConfigStore} from "./core/store/app-config.store";
import {SvgIconsModule} from "@ngneat/svg-icon";
import {PopupContainerComponent} from "./components/popup-container/popup-container.component";
import {ConfirmPopupComponent} from "./components/confirm-popup/confirm-popup.component";
import {LoaderComponent} from "./components/loader/loader.component";
import {SetChatConfig} from "./core/chatConfig";
import * as moment from "moment";
import "moment-timezone"
import "moment-duration-format"
import {ApiInterceptor} from "@shared/core/services/api-interceptor.service";
import {MatMaterialModule} from "@shared/core/modules/mat-matrial.module";
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {RatingModule} from 'ngx-bootstrap/rating';
import {TypeaheadModule} from 'ngx-bootstrap/typeahead';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import {DrupalSettingsStore} from "@shared/core/store/drupal-settings.store";
import {drupalSettings} from "@shared/core/drupalSettings";
import {EventTimeComponent} from "@shared/components/event-time/event-time.component";
import {AudioPlayerComponent} from "@shared/components/audio-player/audio-player.component";
import {FormsModule} from "@angular/forms";
import {AutosizeModule} from 'ngx-autosize';
import {UserListItemComponent} from "@shared/components/user-list-item/user-list-item.component";
import {CampuzButtonDirective} from "@shared/directives/campuz-button.directive";
import {TranslateModule} from "@ngx-translate/core";
import {AdDirective} from "@shared/directives/ad.directive";

const resources = [
];

const services = [
];

const components = [
  PopupContainerComponent,
  ConfirmPopupComponent,
  LoaderComponent,
  EventTimeComponent,
  AudioPlayerComponent,
  UserListItemComponent,
];

const entryComponents = [
  PopupContainerComponent,
  ConfirmPopupComponent,
];

const modules = [
  NgbModule,
  RatingModule,
  TypeaheadModule.forRoot(),
  CKEditorModule,
  AutosizeModule,
];

const pipes = [

]

const directives = [
  CampuzButtonDirective,
  AdDirective,
]

const stores = [
  AppConfigStore,
  DrupalSettingsStore,
]

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatMaterialModule,
    HttpClientModule,
    TranslateModule.forChild(),
    SvgIconsModule,
    ...modules,
  ],
  providers: [
    {provide: APP_BASE_HREF, useValue : '/' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    ...resources,
    ...services,
    ...stores,
  ],
  declarations: [
    ...directives,
    ...components,
    ...pipes,
  ],
  entryComponents: [
    ...entryComponents
  ],
  exports: [
    ...components,
    ...directives,
    TranslateModule,
    SvgIconsModule,
    CommonModule,
    MatMaterialModule,
    NgbModule,
    RatingModule,
    TypeaheadModule,
    CKEditorModule,
    AutosizeModule,
    CampuzButtonDirective,
  ]
})
export class SharedModule {
  constructor (private appConfigStore: AppConfigStore) {
    window['campuzApp'] = {}
    moment.locale(drupalSettings?.lang || 'ru');
    SetChatConfig(window?.['Drupal']?.settings?.chat || {});
    appConfigStore.initApp();
  }
}


