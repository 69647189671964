<div class="popup-container">
  <div *ngIf="!options.hideCloseIcon" class="popup-container__close-dialog" (click)="close.emit()">
    <svg-icon key="closePopup"></svg-icon>
  </div>
  <a *ngIf="headerUrl" [href]="headerUrl" class="popup-container__header" [ngClass]="{'popup-container__header--left-side': options.placeHeaderOnLeftSide}">{{title}}</a>
  <div *ngIf="!headerUrl" class="popup-container__header" [ngClass]="{'popup-container__header--left-side': options.placeHeaderOnLeftSide}">{{title}}</div>
  <div class="popup-container__separator"></div>
  <div class="popup-container__content" [ngStyle]="{marginBottom: (actions.length) ? '' : '0'}">
    <ng-content></ng-content>
  </div>
  <div *ngIf="actions.length" class="popup-container__actions" [ngClass]="{'popup-container__actions--disable-buttons-flex-grow': options.disableButtonsFlexGrow}">
    <button *ngFor="let action of actions"
            [ngStyle]="{opacity: action.disabled ? '0.5' : '1', pointerEvents: action.disabled ? 'none' : 'auto'}"
            class="popup-container__button popup-container__button--{{action.type || 'regular'}}"
            (click)="onActionButtonClick($event, action.action)">
      <svg-icon *ngIf="action.icon" [key]="action.icon" class="popup-container__button-icon"></svg-icon>
      <span>{{action.title}}</span>
    </button>
  </div>
</div>
