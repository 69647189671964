import {Injectable} from "@angular/core";
import {CookieService} from "ngx-cookie-service";
import {DIControlService} from "@core/services/di-control.service";

/**
 * A service which wraps CookieService and specifies some default params, like path or cookie domain.
 */
@Injectable({
  providedIn: 'root',
})
export class AppCookieService extends DIControlService {

  constructor(private cookieService: CookieService) {
    super();
  }

  get(name: string): string {
    return this.cookieService.get(name);
  }

  getAll() {
    return this.cookieService.getAll();
  }

  set(name: string, value: string, expires?: number | Date, path: string = '/', domain: string = window.location.host, secure?: boolean, sameSite?: 'Lax' | 'Strict'): void {
    this.cookieService.set(name, value, expires, path, domain, secure, sameSite);
  }

  delete(name: string, path: string = '/', domain: string = window.location.host): void {
    this.cookieService.delete(name, path, domain);
  }
  /**
   * @param path   Cookie path
   * @param domain Cookie domain
   */
  deleteAll(path: string = '/', domain: string = window.location.host): void {
    this.cookieService.deleteAll(path, domain);
  }
}
