import {
  ApplicationRef,
  Compiler,
  ComponentFactoryResolver,
  DoBootstrap,
  Injector,
  NgModule,
} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
// import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
// import {CustomTranslationsLoader} from "@shared/translations/translations-loader.service";
import {AppCookieService} from "@shared/core/services/cookie.service";
import {MatDialogModule} from "@angular/material/dialog";
import {SvgIconsModule} from "@ngneat/svg-icon";
import icons from "@shared/assets/svg/svg-icons";
import {SharedModule} from "@shared/shared.module";
import {NgSelectModule} from "@ng-select/ng-select";
import {ToastrModule} from "ngx-toastr";
import {AppInfoStore} from "@shared/core/store/app-info.store";
import {take} from "rxjs/operators";
// import {StorageService} from "@core/services/storage.service";
import {FormsModule} from "@angular/forms";
import {Apps, SupportedLanguages} from "./app.config";
import {environment} from "./environments/environment";
import {drupalSettings} from "@shared/core/drupalSettings";
import {CoreModule} from "@core/core.module";
// import {SocketService} from "@core/services/socket.service";

interface IApp {
  selector: string;
  dirName: string;
  moduleName: string;
}

@NgModule({
  declarations: [],
  imports: [
    BrowserModule,
    SharedModule,
    CoreModule,
    MatDialogModule,
    FormsModule,
    NgSelectModule,
    SvgIconsModule.forRoot({
      icons
    }),
    BrowserAnimationsModule,
    // TranslateModule.forRoot({
    //   loader: {
    //     provide: TranslateLoader,
    //     useClass: CustomTranslationsLoader
    //   }
    // }),
    ToastrModule.forRoot(),
  ],
  providers: [
    AppCookieService,
    AppInfoStore,
    // StorageService,
    // SocketService,
  ],
})
export class AppModule implements DoBootstrap {
  constructor(private appInfoStore: AppInfoStore,
              private resolver: ComponentFactoryResolver,
              private compiler: Compiler,
              private injector: Injector) {
    if (!environment.production) {
      console.log(`%cДля вывода времени используется: %c${drupalSettings.activeTimezone} time`, 'color: green', 'color: orangered');
    }
  }

  ngDoBootstrap(appRef: ApplicationRef) {
    this.appInfoStore.isReady$.pipe(take(1))
      .subscribe( (res) => {
        Apps.forEach(async (app: IApp) => {
          const elements = document.querySelectorAll(app.selector);
          if (elements.length) {
            const moduleObj = await import(/* webpackChunkName: "[request]" */ `./apps/${app.dirName}/${app.moduleName}.module`);
            const module = moduleObj[Object.keys(moduleObj)[0]];
            const moduleFactory = await this.compiler.compileModuleAsync(module);
            moduleFactory.create(this.injector);
          }
        });
      })
  }
}
