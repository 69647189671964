import {Directive, ElementRef, Input, OnChanges, SimpleChanges} from '@angular/core';
export type CampuzButtonTypes = 'regular' | 'submit' | 'danger' | 'search';

@Directive({
  selector: '[campuzButton]'
})
export class CampuzButtonDirective implements OnChanges {
  @Input() campuzButton: 'regular' | 'submit' | 'danger' | 'search' = 'regular';

  constructor(private el: ElementRef) {
    el.nativeElement.classList.add('campuz-button');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['campuzButton']) {
      if (changes['campuzButton'].previousValue) {
        this.el.nativeElement.classList.remove(`campuz-button--${changes['campuzButton'].previousValue}`);
      }
      if (changes['campuzButton'].currentValue) {
        this.el.nativeElement.classList.add(`campuz-button--${changes['campuzButton'].currentValue}`);
      }
    }
  }
}
