import {Injectable} from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';

@Injectable({providedIn: 'root'})
export class CdkOverlayContainer extends OverlayContainer  {

  public myCreateContainer(element: HTMLElement): void {
    this._containerElement = element;
  }

  protected _createContainer(): void {
    return;
  }
}
