import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styleUrls: ['./confirm-popup.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmPopupComponent implements OnInit {

  src: string = '';
  type: string = '';
  isReady: boolean = false;

  actions = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public payload: any,
    public dialogRef: MatDialogRef<ConfirmPopupComponent>,
    private translateService: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
    this.isReady = true;
    this.actions = [
      {
        type: 'regular',
        title: this.translateService.instant(this.payload?.cancelTitle || 'COMMON.CANCEL'),
        action: 'cancel',
      },
      {
        type: 'danger',
        title: this.translateService.instant(this.payload?.submitTitle || 'COMMON.DELETE'),
        action: 'submit',
      }
    ];
  }

  callAction(event) {
    switch (event) {
      case 'submit':
        this.submit();
        break;
      case 'cancel':
        this.closeDialog();
        break;
    }
  }

  submit() {
    try {
      this.payload.onConfirm();
    }
    catch (e) {
      console.error('Error in ConfirmPopupComponent', e);
    }
    this.closeDialog();
  }

  closeDialog() {
    this.dialogRef.close();
    this.changeDetectorRef.detectChanges();
  }

}
