import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, map} from "rxjs/operators";
import {drupalSettings} from "@shared/core/drupalSettings";

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  public socket;
  public initialized = new BehaviorSubject(false);
  public ready$ = new BehaviorSubject(false);
  public onReady$ = this.ready$.pipe(filter(Boolean));
  private counter: number;
  private userId = drupalSettings.campuz.sockets.userId || 1;

  constructor() {
    this.connect();
  }

  connect() {
    if (this.socket) {
      this.initialized.next(true);
      this.ready$.next(true);
    } else {
      if (window["Campuz"]) {
        this.socket = window["Campuz"].sockets.io;
        if (this.socket) {
          this.initialized.next(true);
          this.ready$.next(true);
        } else {
          this.reconnect();
        }
      } else {
        this.reconnect();
      }
    }
  }

  reconnect() {
    this.counter += 1;
    if (this.counter <= 9) {
      setTimeout(() => {
        this.connect();
      }, 1000);
    }
  }

  public onUpdatePost(): Observable<any> {
    return new Observable<any>(observer => {
      this.socket.on('update_post', (data) => observer.next(data));
    });
  }

  enterChat(chatId) {
    this.socket.emit('enter_chat', {
      chatId, bounce: (() => {
        return Math.random().toString(36).substring(3, 5)
          + Math.random().toString(36).substring(4, 8);
      })()
    });
  }

  public send(text, chatId, extra?): void {
    const payload = {
      text,
      userId: this.userId,
      chatId,
      bounce: (() => {
        return Math.random().toString(36).substring(2, 7);
      })(),
    };
    if (extra) payload['extra'] = extra;
    this.socket.emit('message', payload);
  }

  public onNewChannel(): Observable<any> {
    return new Observable(observer => {
      this.socket.on('new_channel', () => observer.next());
    });
  }

  public like(like, messageId): void {
    const payload = {
      id: messageId,
      state: !like,
      bounce: (() => {
        return Math.random().toString(36).substring(2, 7);
      })(),
    };
    this.socket.emit('like', payload);
  }

  public onMessage(): Observable<any> {
    return new Observable<any>(observer => {
      this.socket.on('new_message', (data) => observer.next(data));
    });
  }

  public onLike(): Observable<any> {
    return new Observable<any>(observer => {
      this.socket.on('like', (data) => observer.next(data));
    });
  }

  public onEvent(event): Observable<any> {
    return new Observable(observer => {
      this.socket.on(event, () => observer.next());
    });
  }

  public getInitState(): Observable<boolean> {
    return this.initialized;
  }

  public onMessageDelete() {
    return new Observable(observer => {
      this.socket.on('message_delete', (data) => observer.next(data));
    });
  }

  public onConnect() {
    return new Observable(observer => {
      this.socket.on('connection', (r) => observer.next(r));
    });
  }

  public onReconnect() {
    return new Observable(observer => {
      this.socket.on('reconnect', (r) => observer.next(r));
    });
  }

  public onConnectError() {
    return new Observable(observer => {
      this.socket.on('connect_error', (err) => observer.next(err));
    });
  }

  public createPoll(id, chatId) {
    const payload = {
      text: 'poll',
      userId: this.userId,
      chatId,
      bounce: (() => {
        return Math.random().toString(36).substring(2, 7);
      })(),
      extra: {polls: [{id}]}
    };

    this.socket.emit("message", payload);
  }

  public votePull(voteId, ids) {
    const payload = {
      id: voteId,
      choices: ids,
      bounce: (() => {
        return Math.random().toString(36).substring(2, 7);
      })(),
    };

    this.socket.emit('poll_vote', payload);
  }

  public onPoll() {
    return new Observable<any>(observer => {
      this.socket.on('vote_success', (data) => observer.next(data));
    });
  }

  public onResults() {
    return new Observable<any>(observer => {
      this.socket.on('poll_results', (data) => observer.next(data));
    });
  }

  messageDisplayed(payload) {
    this.socket.emit('messages_displayed', {...payload,  bounce: (() => {
        return Math.random().toString(36).substring(2, 7);
      })(), });
  }

  readMessages(payload) {
    this.socket.emit('read_messages', {...payload,  bounce: (() => {
        return Math.random().toString(36).substring(2, 7);
      })(), });
  }

  public onEntityUpdate() {
    return new Observable<any>(observer => {
      this.socket.on('entity_update', (data) => observer.next(data));
    });
  }

  public onSessionUpdate() {
    return new Observable<any>(observer => {
      this.onEntityUpdate().pipe(
        filter( data => data.type === 'session'),
        map( data => data.entity)
      ).subscribe( session => observer.next(session))
    });
  }

  public onCoMeetingUpdate() {
    return new Observable<any>(observer => {
      this.onEntityUpdate().pipe(
        filter( data => data.type === 'co_meeting'),
        map( data => data.entity)
      ).subscribe( session => observer.next(session))
    });
  }
}

