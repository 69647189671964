<div class="user-list-item user-list-item--{{size}}">
  <a [href]="'/user/' + user?.id" target="_blank" class="user-list-item__avatar">
    <img [src]="user?.imageUrl || DEFAULT_AVATAR_URL">
  </a>
  <div class="user-list-item__info">
    <a [href]="'/user/' + user?.id" target="_blank" class="user-list-item__name">{{ user?.surName }} {{ user?.name }}</a>
    <div class="user-list-item__description">
      <span *ngIf="user?.profession">{{ user?.profession }}</span>
      <i>, </i>
      <span *ngIf="user?.company">{{ user?.company }}</span>
    </div>
  </div>
</div>
