import {Component, Input, OnInit} from '@angular/core';
import * as moment from "moment";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-event-time',
  templateUrl: './event-time.component.html',
  styleUrls: ['./event-time.component.less'],
})
export class EventTimeComponent implements OnInit {
  @Input() start: number;
  @Input() end: number;
  @Input() isAllDayEvent: boolean;
  @Input() withoutIcons: boolean = false;

  date: string;
  time: string;
  constructor(private translateService: TranslateService) {}

  ngOnInit() {
    if (!this.end || this.start === this.end) {
      const start = moment(this.start);
      if (this.isAllDayEvent) {
        this.date = `${start.format('LL')}`;
        this.time = this.translateService.instant('CALENDAR.ALL_DAY_EVENT');
      }
      else {
        this.date = `${start.format('LL')}`;
        this.time = `${start.format('LT')}`;
      }
    }
    else {
      const start = moment(this.start);
      const end = moment(this.end);
      if (start.format('LL') !== end.format('LL')) {
        if (this.isAllDayEvent) {
          this.date = `${start.format('LL')} – ${end.format('LL')}`;
        }
        else {
          this.date = `${start.format('LT')} ${start.format('LL')} – ${end.format('LT')} ${end.format('LL')}`;
        }
      }
      else {
        if (this.isAllDayEvent) {
          this.date = `${start.format('LL')}`;
          this.time = this.translateService.instant('CALENDAR.ALL_DAY_EVENT');
        }
        else {
          this.date = `${start.format('LL')}`;
          this.time = `${start.format('LT')} – ${end.format('LT')}`;
        }
      }
    }

  }

}
