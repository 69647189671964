import {Injectable, Injector} from "@angular/core";
import * as LZString from "lz-string"
import {environment} from "../../environments/environment";
import {DIControlService} from "@core/services/di-control.service";

@Injectable({
  providedIn: 'root',
})
export class StorageService extends DIControlService {

  private campuzStorage: any;

  constructor() {
    super();
    this.campuzStorage = JSON.parse(LZString.decompressFromBase64(localStorage.getItem('campuz')) || '{}');

    if (!environment.production) {
      console.log(this.campuzStorage)
    }
  }

  set(name: string, value: any) {
    this.campuzStorage[name] = value;
    const data = JSON.stringify(this.campuzStorage);
    const compressData = LZString.compressToBase64(data);
    localStorage.setItem('campuz', compressData);

    if (!environment.production) {
      console.log('LocalStorageSizeJSON', new Blob([data]).size)
      console.log('LocalStorageSizeCompressed', new Blob([compressData]).size);
    }
  }

  get(name: string): any {
    return this.campuzStorage[name]
  }
}
