<div class="event-time" [ngClass]="{'event-time--without-icons': withoutIcons}">
  <span *ngIf="date" class="event-time__block">
    <svg-icon *ngIf="!withoutIcons" key="calendar" class="event-tooltip__date-icon"></svg-icon>
    {{date}}
  </span>
  <span *ngIf="time" class="event-time__block">
    <svg-icon *ngIf="!withoutIcons" key="clock" class="event-tooltip__date-icon"></svg-icon>
    {{time}}
  </span>
</div>
