import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import {AppConfigStore} from "../store/app-config.store";
import {drupalSettings} from "@shared/core/drupalSettings";
import {DIControlService} from "@core/services/di-control.service";

@Injectable()
export class ApiInterceptor extends DIControlService implements HttpInterceptor {
  constructor(private cookieService: CookieService, private appConfigStore: AppConfigStore) {
    super();
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const settings = drupalSettings;
    const apiKey = `Api-key ${this.appConfigStore.state.apikey}`;
    const token = settings?.campuz ? `Bearer ${settings?.campuz?.sockets?.token}` : '';
    const lang = settings?.campuz ? settings.lang || settings.campuz.lang : 'ru';
    const userId = settings?.campuz ? settings?.campuz?.sockets?.userId : '1';
    const headers: any = {};
    if (apiKey) {
      headers.Authorization = apiKey;
    }
    if (token) {
      headers['X-Api-Authorization'] = token;
    }
    if (userId) {
      headers['X-user-id'] = userId;
    }
    headers['X-Api-Language'] = lang;

    // Standard set of our custom headers.
    request = request.clone({
      setHeaders: {...headers},
      withCredentials: true,
    });

    // Set default headers.
    try {
      if (!request.headers.has('Content-Type')) {
        request = request.clone({
          setHeaders: {
            'Content-Type': 'application/json'
          }
        });
      }
    }
    catch (e) { }


    return next.handle(request);
  }
}
