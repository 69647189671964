import {NgModule} from "@angular/core";
import {SocketService} from "@core/services/socket.service";
import {BootstrapService} from "@core/services/bootstap.service";
import {TranslateLoader, TranslateModule, TranslateService} from "@ngx-translate/core";
import {drupalSettings} from "@shared/core/drupalSettings";
import {CustomTranslationsLoader} from "@core/services/translations-loader.service";
import {OverlayContainer} from "@angular/cdk/overlay";
import {CdkOverlayContainer} from "@core/services/cdk-overlay-container.service";
import {DialogService} from "@core/services/dialog.service";
import {CommunicationService} from "@core/services/communication.service";
import {MainBusService} from "@core/services/main-bus.service";
import {StorageService} from "@core/services/storage.service";
import {DateFnsService} from "@core/services/date-fns.service";
import * as moment from "moment";


const modules = [
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useClass: CustomTranslationsLoader
    }
  }),
];

const components = [
];

const entryComponents = [
];

const directives = [
];

const pipes = [
];

const resources = [
];

const services = [
  SocketService,
  BootstrapService,
  DialogService,
  CommunicationService,
  MainBusService,
  StorageService,
  DateFnsService,
];

const stores = [
];

@NgModule({
  imports: [
    ...modules,
  ],
  providers: [
    { provide: OverlayContainer, useClass: CdkOverlayContainer },
    ...resources,
    ...services,
    ...stores,
  ],
  declarations: [
    ...components,
    ...directives,
    ...pipes,
  ],
  entryComponents: [
    ...entryComponents
  ],
  exports: [
  ]
})
export class CoreModule {
  constructor (private dateFnsService: DateFnsService, private translateService: TranslateService,) {
    this.dateFnsService.setDefaultLocale(drupalSettings?.lang || 'ru');
    if (drupalSettings.activeTimezone === 'site') {
      moment.tz.setDefault(drupalSettings?.timezone || 'Europe/Moscow');
      this.dateFnsService.setDefaultTimeZone(drupalSettings?.timezone || 'Europe/Moscow');
    }
    translateService.use(drupalSettings?.lang || 'ru');
  }
}


