import {MatDialog} from "@angular/material/dialog";
import {Injectable, ViewContainerRef} from "@angular/core";
import {OverlayContainer} from "@angular/cdk/overlay";
import {DIControlService} from "@core/services/di-control.service";

@Injectable({
  providedIn: 'root',
})
export class DialogService extends DIControlService {

  dialogContainer: ViewContainerRef;

  constructor(public dialog: MatDialog,
              protected cdkOverlayContainer: OverlayContainer) {
    super();
  }

  openDialog(dialogComponent, data, element?) {
    this.cdkOverlayContainer['myCreateContainer'](element || document.querySelector('body'));
    const dialog = this.dialog.open(dialogComponent, data);

    if (element) {
      (dialog as any)._overlayRef.backdropElement.style.position = 'absolute';
      (dialog as any)._overlayRef.hostElement.style.position = 'absolute';
    }
    else {
      (dialog as any)._overlayRef.backdropElement.style.position = 'fixed';
      (dialog as any)._overlayRef.hostElement.style.position = 'fixed';
    }
    return dialog;
  }
}
