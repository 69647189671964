import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {Directive} from "@angular/core";
import {DIControlService} from "@core/services/di-control.service";

export interface IStoreAction {
  type: string;
  [id: string]: any;
}

export interface IStore<T> {
  action$: Observable<IStoreAction>;
  state$: Observable<T>;

  readonly state: T;
}

@Directive()
export class StoreService<T> extends DIControlService implements IStore<T> {

  protected subs: Subscription[] = [];
  protected stateSubject: BehaviorSubject<T>;
  protected actionSubject = new Subject<IStoreAction>();

  public action$ = this.actionSubject.asObservable();
  public state$: Observable<T>;

  public get state(): T { return this.stateSubject.value; }

  constructor(defaultState: T) {
    super();
    this.stateSubject = new BehaviorSubject<T>(defaultState);
    (this.state$ as any) = this.stateSubject
      .asObservable()
      // Remove empty values.
      .pipe(filter(Boolean));
  }

  select<I>(selector: (state: T) => I) {
    return this.state$.pipe(
      map(state => selector(state)),
      filter(value => Boolean(value))
    );
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
