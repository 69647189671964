import {Injectable} from '@angular/core';
import {DIControlService} from "@core/services/di-control.service";

@Injectable({
  providedIn: 'root',
})
export class CommunicationService extends DIControlService {

  constructor() {
    super();
  }

  send(event: string, payload: any) {
    window.dispatchEvent(new CustomEvent('campuz-message', { detail: { event, payload } }));
  }
}

