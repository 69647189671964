import {Injectable} from '@angular/core';
import {IStore, StoreService} from "./store";
import {IAppConfig} from "../interfaces/app-config.interface";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root',
})
export class AppConfigStore extends StoreService<IAppConfig.IConfig> implements IStore<IAppConfig.IConfig> {

  static defaultState: IAppConfig.IConfig = {
    apiUrl: '',
    apikey: '',
    blocks_display: [],
    languages: [],
    menu: [],
    themes: [],
    lang: '',
    channelId: null,
    user_id: null,
    default_language: IAppConfig.LANGUAGES.RU,
    main_chat_channel: null,
    isLoggedIn: false,
    activeLanguage: IAppConfig.LANGUAGES.RU,
  };

  constructor() {super(AppConfigStore.defaultState);}

  initApp() {
    const result = {
      user_id: null,
      apikey: '',
      lang: '',
    }

    if (window['Drupal']) {
      result.user_id = window['Drupal'].settings.campuz.sockets.userId;
      result.apikey = window['Drupal'].settings.campuz.apikey;
      result.lang = window['Drupal'].settings.lang || window['Drupal'].settings.campuz.lang;
    } else {
      result.apikey = "igebSf9ncahfZKZo";
      result.user_id = 1;
      result.lang = 'en';
    }

    this.stateSubject.next({...result});
  }

  modifyStore(key: string, data: any) {
    this.stateSubject.next({
      ...this.state,
      [key]: data
    });
  }
}

