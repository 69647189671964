import {ApplicationRef, ComponentFactoryResolver, Injectable} from "@angular/core";
import {environment} from "../../environments/environment";
import {DIControlService} from "@core/services/di-control.service";


@Injectable({
  providedIn: 'root',
})
export class BootstrapService extends DIControlService {

  constructor(private applicationRef: ApplicationRef,
              private componentFactoryResolver: ComponentFactoryResolver){
    super();
  }

  bootstrap(entrypointComponent: any, entrypointSelector: string) {
    const elements = document.querySelectorAll(entrypointSelector);
    if (!environment.production && elements.length) {
      console.log(`%cНайдена точка входа: %c${entrypointSelector}`, 'color: green', 'color: orangered');
    }
    elements.forEach( (root) => {
      let factory = this.componentFactoryResolver.resolveComponentFactory(entrypointComponent);
      this.applicationRef.bootstrap(factory, root);
    })
  }
}
