import {Component, Input} from '@angular/core';


@Component({
  selector: 'app-user-list-item',
  templateUrl: './user-list-item.component.html',
  styleUrls: ['./user-list-item.component.less'],
})
export class UserListItemComponent {
  @Input() user: any;
  @Input() size: 'medium' | 'large' = 'medium'

  DEFAULT_AVATAR_URL = '/sites/all/themes/campadmin/dist/default/assets/app/media/img/users/user-anonim.jpg';


  constructor() {}
}
