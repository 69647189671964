import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AudioPlayerComponent {

  @Input() audioSrc: string;

  isPlaying: boolean = false;
  time: string = '00:00';
  value: number = 0;
  volume: number = 100;
  lastVolume: number = 100;
  duration: number = 1;

  ignoreTimeChangeEvent: boolean = false;

  volumeLevelIcons = [
    'speakerMute',
    'speaker1SoundWaves',
    'speaker2SoundWaves',
    'speaker3SoundWaves',
  ];
  currentVolumeLevelIcon: number = 3;

  @ViewChild('audioElement', { static: false }) audio: ElementRef<HTMLAudioElement>;

  constructor(private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.onVolumeLevelChange({ value: 100 });
    this.updateTime(0);
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  onCanplay() {
    this.duration = this.audio.nativeElement.duration || 0;
    this.changeDetectorRef.detectChanges();
  }

  onDurationchange() {
    this.duration = this.audio.nativeElement.duration || 0;
    this.changeDetectorRef.detectChanges();
  }

  onVolumeLevelChange(event) {
    const newIcon = Math.ceil(event.value / (100 / 3));
    this.volume = event.value;
    if (event.value > 0) {
      this.lastVolume = event.value;
    }
    if (this.audio?.nativeElement) {
      this.audio.nativeElement.volume = event.value / 100;
    }
    if (this.currentVolumeLevelIcon !== newIcon) {
      this.currentVolumeLevelIcon = newIcon;
      this.changeDetectorRef.detectChanges();
    }
  }

  onInput(event) {
    this.ignoreTimeChangeEvent = true;
    this.updateTime(event.value);
  }

  onChange(event) {
    this.ignoreTimeChangeEvent = false;
    this.audio.nativeElement.currentTime = event.value;
  }

  onTimeupdate() {
    if (!this.ignoreTimeChangeEvent) {
      this.value = this.audio.nativeElement.currentTime || 0;
      this.updateTime(this.value)
    }
  }

  toggleVolume() {
    this.onVolumeLevelChange({ value : this.volume ? 0 : this.lastVolume });
  }

  updateTime(time: number) {
    this.time = (moment.duration(time, "seconds") as any).format("HH:mm:ss", { trim: false });
    this.changeDetectorRef.detectChanges();
  }

  onPlay() {
    this.isPlaying = true;
  }

  onPause() {
    this.isPlaying = false;
  }

}
