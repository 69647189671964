export let ChatConfig = {
  userId: null,
  userName: null,
  userSurName: null,
  sendPostInNotificationChannel: true,
  isTechSupportModerator: false,
  channelListQuery: 'messenger/channel/list',
  availableChatFilters: {
    notification: true,
    broadcasts: true,
    discussion: true,
    feed: true,
    feedback: true,
    personal: true,
    product_support: false,
    tech_support: true,
    representatives: true,
  }
};

export function SetChatConfig(config) {
  ChatConfig = Object.freeze(Object.assign({}, ChatConfig, config));
}
