<div class="audio-payer">
  <audio [src]="audioSrc" (play)="onPlay()" (pause)="onPause()" (canplay)="onCanplay()" (durationchange)="onDurationchange()" (timeupdate)="onTimeupdate()" hidden #audioElement></audio>
  <svg-icon *ngIf="!isPlaying" key="play" class="audio-payer__icon" (click)="audioElement.play()"></svg-icon>
  <svg-icon *ngIf="isPlaying" key="pause" class="audio-payer__icon" (click)="audioElement.pause()"></svg-icon>
  <div class="audio-payer__track">
    <mat-slider [(ngModel)]="value" [min]="0" [max]="duration" (input)="onInput($event)" (change)="onChange($event)"></mat-slider>
  </div>
  <div class="audio-payer__time">{{time}}</div>
  <svg-icon [key]="volumeLevelIcons[currentVolumeLevelIcon]" class="audio-payer__icon" (click)="toggleVolume()"></svg-icon>
  <div class="audio-payer__volume">
    <mat-slider [(ngModel)]="volume" (input)="onVolumeLevelChange($event)"></mat-slider>
  </div>
</div>
