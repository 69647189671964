import {Injectable} from "@angular/core";
import {
  formatDistanceStrict as formatDistanceStrictOrigin,
  formatDuration as formatDurationOrigin,
  intervalToDuration as intervalToDurationOrigin,
  Locale
} from 'date-fns'
import {format as formatOrigin, utcToZonedTime,} from 'date-fns-tz'
import {enGB, ru, kk} from 'date-fns/locale'
import {drupalSettings} from "@shared/core/drupalSettings";
import {DIControlService} from "@core/services/di-control.service";

const locales = {
  ru: ru,
  en: enGB,
  kk: kk,
}

@Injectable( { providedIn: "root" })
export class DateFnsService extends DIControlService {

  private timeZone: string = '';
  private defaultLocale: Locale = ru;

  constructor() {
    super();
  }

  setDefaultLocale(locale: 'ru' | 'en' | 'kk') {
    if (locale && locales[locale]) {
      this.defaultLocale = locales[locale];
    } else {
      console.warn(`date-fns: locale '${locale}' not found`)
    }
  }

  getDefaultLocale() {
    return this.defaultLocale.code
  }

  setDefaultTimeZone(timeZone: string) {
    this.timeZone = timeZone;
  }

  getDefaultTimeZone() {
    return this.defaultLocale.code
  }

  format(
    date: Date | number,
    format: string,
    options?: {
      weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6
      firstWeekContainsDate?: 1 | 2 | 3 | 4 | 5 | 6 | 7
      additionalDigits?: 0 | 1 | 2
      timeZone?: string
      locale?: Locale
      includeSeconds?: boolean
      addSuffix?: boolean
      unit?: 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year'
      roundingMethod?: 'floor' | 'ceil' | 'round'
      awareOfUnicodeTokens?: boolean
    }
  ) {
    if (this.timeZone) {
      date = utcToZonedTime(date, drupalSettings.timezone)
      options = Object.assign({timeZone: this.timeZone}, options)
    }
    options = Object.assign({locale: this.defaultLocale}, options)
    return formatOrigin(date, format, options) as string;
  }

  formatDistanceStrict(
    date: Date | number,
    baseDate: Date | number,
    options?: {
      addSuffix?: boolean
      unit?: 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year'
      roundingMethod?: 'floor' | 'ceil' | 'round'
      locale?: Locale
    }
  ) {
    options = Object.assign({locale: this.defaultLocale}, options)
    return formatDistanceStrictOrigin(date, baseDate, options) as string;
  }

  formatDuration(
    duration: Duration,
    options?: {
      format?: string[]
      zero?: boolean
      delimiter?: string
      locale?: Locale
    }
  ) {
    options = Object.assign({locale: this.defaultLocale}, options)
    return formatDurationOrigin(duration, options) as string;
  }

  secondsToDuration(seconds) {
    const duration: Duration = {}
    duration.hours = Math.floor(seconds / 3600);
    duration.minutes = Math.floor( (seconds - duration.hours * 3600) / 60 );
    duration.seconds = seconds - duration.hours * 3600 - duration.minutes * 60;
    return duration;
  }
}
