const Apps = [
  {
    "selector": "app-chat-entrypoint",
    "dirName": "Chat",
    "moduleName": "chat"
  },
  {
    "selector": "app-meeting-entrypoint",
    "dirName": "Meeting",
    "moduleName": "meeting"
  },
  {
    "selector": "app-chat-page-entrypoint",
    "dirName": "ChatPage",
    "moduleName": "chat-page"
  },
  {
    "selector": "app-exposition-entrypoint",
    "dirName": "Expo",
    "moduleName": "exposition"
  },
  {
    "selector": "app-program-entrypoint",
    "dirName": "Program",
    "moduleName": "program"
  },
  {
    "selector": "app-current-event-entrypoint",
    "dirName": "CurrentEvent",
    "moduleName": "current-event"
  },
  {
    "selector": "app-calendar-entrypoint",
    "dirName": "Calendar",
    "moduleName": "calendar"
  },
  {
    "selector": "app-related-broadcasts-entrypoint",
    "dirName": "RelatedBroadcasts",
    "moduleName": "related-broadcasts"
  },
  {
    "selector": "app-posters-page-entrypoint",
    "dirName": "PostersPage",
    "moduleName": "posters-page"
  },
  {
    "selector": "app-co-meeting-entrypoint",
    "dirName": "CoMeeting",
    "moduleName": "co-meeting"
  },
  {
    "selector": "app-map-entrypoint",
    "dirName": "Map",
    "moduleName": "map"
  }
]

const SupportedLanguages = [
  'ru',
  'en',
  'kk'
];

module.exports = {Apps, SupportedLanguages}
