import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

export interface IPopupContainerOptions {
  hideCloseIcon?: boolean;
  disableButtonsFlexGrow?: boolean;
  placeHeaderOnLeftSide?: boolean;
}

@Component({
  selector: 'app-popup-container',
  templateUrl: './popup-container.component.html',
  styleUrls: ['./popup-container.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupContainerComponent {
  @Input() title: string = '';
  @Input() headerUrl: string = '';
  @Input() actions: any = [];
  @Input() options?: IPopupContainerOptions = {};
  @Output() callAction = new EventEmitter<string>()
  @Output() close = new EventEmitter<void>()
  constructor() {}

  onActionButtonClick(event, action) {
    event.stopPropagation();
    event.preventDefault();
    this.callAction.emit(action)
  }
}
