import {Injectable} from '@angular/core';
import {IStore, StoreService} from "./store";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {distinctUntilChanged, filter, map} from "rxjs/operators";
import {StorageService} from "@core/services/storage.service";
import {drupalSettings} from "@shared/core/drupalSettings";

@Injectable({
  providedIn: 'root',
})
export class AppInfoStore extends StoreService<any> implements IStore<any> {

  static defaultState = {
    isInit: false,
    reltypes: {},
  };

  isReady$: Observable<any> = this.state$
    .pipe(
      map(state => state.isInit),
      filter(Boolean),
      distinctUntilChanged(),
    );

  constructor(private httpClient: HttpClient,
              private storageService: StorageService,) {
    super(AppInfoStore.defaultState);
    this.initApp();
  }

  initApp() {
    const parseInfo = (result: any) => {
      const data = result?.data;
      let reltypes = {};

      if (data?.reltypes) {
        reltypes = this.prepareReltypes(data.reltypes);
      }

      this.stateSubject.next({
        ...this.state,
        isInit: true,
        reltypes,
      });
    }

    const config = this.storageService.get('/api/v2/info') || {};
    if (!config?.hash || !drupalSettings?.config_hash || (config?.hash !== drupalSettings?.config_hash)) {
      this.httpClient.get('/api/v2/info').subscribe( (result) => {
        parseInfo(result);
        this.storageService.set('/api/v2/info', {
          hash: drupalSettings.config_hash,
          result,
        })
      })
    }
    else {
      parseInfo(config.result);
    }
  }

  prepareReltypes(reltypes) {
    const prepareReltypes: any = {};
    reltypes.forEach((reltype) => {
      prepareReltypes[reltype.relation] = {
        title: {}
      };
      reltype.title.forEach( title => {
        prepareReltypes[reltype.relation].title[title.lang] = {
          single: title.single,
          multiple: title.multiple,
        }
      })
    })
    return prepareReltypes;
  }

}

