<div class="loader__dots" [ngStyle]="{width: size + 'px', height: size + 'px', top: 'calc(50% - ' + size + 'px);', left: 'calc(50% - ' + size + 'px);'}">
  <div *ngFor="let i of Arr(12).fill(1)"
       class="loader__dot"
       [ngStyle]="{
         width: size / 10 + 'px',
         height: size / 10 + 'px',
         top: size / 2 - size / 20 + 'px',
         transformOrigin: size / 2 + 'px 50%'
       }"></div>
</div>


