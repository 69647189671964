import { Injectable } from '@angular/core';
import {IStore, StoreService} from "@shared/core/store/store";

@Injectable({
  providedIn: 'root',
})
export class DrupalSettingsStore extends StoreService<any> implements IStore<any> {

  static defaultState = {
  };


  constructor() {
    super(DrupalSettingsStore.defaultState);
    this.loadSettings();
    window['campuzApp'].reloadSettings = this.loadSettings.bind(this);
  }

  loadSettings() {
    this.stateSubject.next({
      ...this.state,
      ...(window as any)?.Drupal?.settings || {},
    });
  }


}
